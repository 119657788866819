import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';

import TwitterLogo from 'assets/svg/marketing/twitter-icon.svg';
import DiscordLogo from 'assets/svg/social/discord.svg';
import GithubLogo from 'assets/svg/social/github.svg';
import MediumLogo from 'assets/svg/social/medium.svg';
import MirrorLogo from 'assets/svg/social/mirror.svg';
import TelegramLogo from 'assets/svg/social/telegram.svg';
import GalxeLogo from 'assets/svg/social/galxe.svg';
import { FlexDivCentered, FlexDivColCentered } from 'components/layout/flex';
import { Body } from 'components/Text';
import { EXTERNAL_LINKS } from 'constants/links';
import { GridContainer } from 'sections/homepage/section';
import { StackSection } from 'sections/homepage/section';
import { ExternalLink } from 'styles/common';
import media from 'styles/media';
import { themes } from 'styles/theme';

import Logo from '../Logo';

const Footer = memo(() => {
	const { t } = useTranslation();

	return (
		<ThemeProvider theme={themes.dark}>
			<Container>
				<PowerContainer>
					<StackSection>
						<LogoContainer>
							<Logo />
						</LogoContainer>
						<CopyRight>{t('homepage.footer.copyright')}</CopyRight>
					</StackSection>
				</PowerContainer>
			</Container>
		</ThemeProvider>
	);
});

const LogoContainer = styled.div`
	margin-left: 20px;
	margin-bottom: 10px;
`;

const Container = styled(FlexDivColCentered)`
	justify-content: center;
`;

const CopyRight = styled.div`
	font-size: 12px;
	text-align: right;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	opacity: 0.5;
	margin-right: 20px;
	padding-top: 10px;
	${media.lessThan('sm')`
		margin-right: 0px;
		padding-top: 0px;
	`};
`;

const PowerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 40px;
	border-top: 1px solid #3d3c3c;
	margin-bottom: 50px;
	${media.lessThan('sm')`
		width: 355px;
		padding-left: 10px;
		padding-right: 10px;
		gap: 20px 80px;
		flex-wrap: wrap;
		padding-top: 60px;
		border-top: 1px solid #3d3c3c;
		justify-content: center;
		margin-bottom: 100px;
	`};
`;

const LogoFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 30px;
`;

const SocialIcons = styled(FlexDivCentered)`
	> * + * {
		margin-left: 24px;
	}

	${media.lessThan('sm')`
		padding-top: 5px;
	`};

	svg {
		color: ${(props) => props.theme.colors.common.primaryWhite};
	}
`;

export default Footer;
