import Link from 'next/link';
import router from 'next/router';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ArrowUpRightIcon from 'assets/svg/app/arrow-up-right-tg.svg';
import CaretDownGrayIcon from 'assets/svg/app/caret-down-gray-slim.svg';
import TwitterLogo from 'assets/svg/marketing/twitter-icon.svg';
import DiscordLogo from 'assets/svg/social/discord.svg';
import GithubLogo from 'assets/svg/social/github.svg';
import MediumLogo from 'assets/svg/social/medium.svg';
import TelegramLogo from 'assets/svg/social/telegram.svg';
import GalxeLogo from 'assets/svg/social/galxe.svg';
import Button from 'components/Button';
import { FlexDivCentered, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex';
import { GridDivCenteredCol } from 'components/layout/grid';
import { MobileHiddenView, MobileOnlyView } from 'components/Media';
import { Body } from 'components/Text';
import { EXTERNAL_LINKS } from 'constants/links';
import ROUTES from 'constants/routes';
import media from 'styles/media';

import MobileUserMenu from '../AppLayout/Header/MobileUserMenu';
import Logo from '../Logo';

export type TPages = 'landing-page' | 'stats-page';

const Header = memo(() => {
	const { t } = useTranslation();

	const LINKS = useMemo(
		() => [
			{
				id: 'perpetuals',
				label: t('homepage.nav.perpetuals'),
				onClick: () => {},
			},
			{
				id: 'pools',
				label: t('homepage.nav.pools'),
				onClick: () => {},
			},
			// {
			// 	id: 'market',
			// 	label: t('homepage.nav.markets'),
			// 	onClick: () => router.push(ROUTES.Dashboard.Markets),
			// },
			// {
			// 	id: 'stats',
			// 	label: t('homepage.nav.stats'),
			// 	onClick: () => router.push(ROUTES.Stats.Home),
			// },
			{
				id: 'swap',
				label: t('homepage.nav.swap'),
				onClick: () => {},
			},
			{
				id: 'faucet',
				label: t('homepage.nav.faucet'),
				onClick: () => {},
			},
			// {
			// 	id: 'socials',
			// 	label: t('homepage.nav.socials.title'),
			// 	icon: <CaretDownGrayIcon />,
			// },
			// {
			// 	id: 'governance',
			// 	label: t('homepage.nav.governance'),
			// 	icon: <ArrowUpRightIcon />,
			// 	onClick: () => window.open(EXTERNAL_LINKS.Governance.Vote, '_blank'),
			// },
			{
				id: 'learn-more',
				label: t('homepage.nav.learn-more'),
				icon: <ArrowUpRightIcon />,
				onClick: () => window.open(EXTERNAL_LINKS.Docs.DocsRoot, '_blank'),
			},
		],
		[t]
	);

	const SOCIALS = [
		{
			id: 'medium',
			label: t('homepage.nav.socials.medium'),
			onClick: () => window.open(EXTERNAL_LINKS.Social.Medium, '_blank'),
			icon: <MediumLogo />,
			disabled: true,
		},
		// {
		// 	id: 'twitter',
		// 	label: t('homepage.nav.socials.twitter'),
		// 	onClick: () => window.open(EXTERNAL_LINKS.Social.Twitter, '_blank'),
		// 	icon: <TwitterLogo />,
		// 	disabled: true,
		// },
		// {
		// 	id: 'discord',
		// 	label: t('homepage.nav.socials.discord'),
		// 	onClick: () => window.open(EXTERNAL_LINKS.Social.Discord, '_blank'),
		// 	icon: <DiscordLogo />,
		// 	disabled: true,
		// },
		// {
		// 	id: 'telegram',
		// 	label: t('homepage.nav.socials.telegram'),
		// 	onClick: () => window.open(EXTERNAL_LINKS.Social.Telegram, '_blank'),
		// 	icon: <TelegramLogo />,
		// 	disabled: true,
		// },
		// {
		// 	id: 'github',
		// 	label: t('homepage.nav.socials.galxe'),
		// 	onClick: () => window.open(EXTERNAL_LINKS.Social.Galxe, '_blank'),
		// 	icon: <GalxeLogo />,
		// 	disabled: true,
		// },
	];

	return (
		<>
			<MobileHiddenView>
				<Container>
					<div onClick={() => router.push(ROUTES.Home.Root)}>
						<Logo />
					</div>
					<Links>
						{LINKS.map(({ id, label, icon, onClick }) => (
							<StyledTextButton key={id} className={id} onClick={onClick}>
								<FlexDivRowCentered>
									{label}
									{icon}
								</FlexDivRowCentered>
							</StyledTextButton>
						))}
					</Links>
					<MenuContainer>
							<Button noOutline size="medium" textColor="secondary">
								{t('homepage.nav.start-trade')}
							</Button>
					</MenuContainer>
				</Container>
			</MobileHiddenView>
			<MobileOnlyView>
				<MobileContainer>
					<div onClick={() => router.push(ROUTES.Home.Root)}>
						<Logo />
					</div>
					<MobileUserMenu />
				</MobileContainer>
			</MobileOnlyView>
		</>
	);
});

const MobileContainer = styled(FlexDivRow)`
	justify-content: center;
	align-items: center;
`;

const StyledMenu = styled.div`
	position: absolute;
	background: ${(props) => props.theme.colors.selectedTheme.cell.fill};
	border: 1px solid rgba(255, 255, 255, 0.1);
	z-index: 10;
	border-radius: 6px;
	max-width: 150px;
	margin: auto;
	padding: 10px 15px;
	margin-top: 35px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.governance {
		visibility: hidden;
		transition: visibility 0.1s;
		:hover {
			visibility: visible;
		}
	}

	&.socials {
		visibility: hidden;
		transition: visibility 0.1s;
		:hover {
			visibility: visible;
		}
	}
`;

const StyledMenuItem = styled(Body).attrs({ weight: 'bold' })`
	cursor: pointer;
	width: 100%;
	font-size: 15px;
	height: 30px;
	color: ${(props) => props.theme.colors.common.secondaryGray};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-top: 0px;
	padding-bottom: 0px;
	margin: 0px;
	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.white};
	}
	svg {
		margin-right: 10px;
		width: 15px;
		height: 15px;
	}
`;

const Container = styled.header`
	display: grid;
	align-items: center;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
`;

const Links = styled.div`
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	justify-self: center;
`;

const StyledTextButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 15px;
	line-height: 15px;
	font-family: ${(props) => props.theme.fonts.bold};
	color: ${(props) => props.theme.colors.common.tertiaryGray};
	cursor: pointer;
	padding: 8px 13px;
	border-radius: 100px;

	&:hover {
		background: #252525;
		color: ${(props) => props.theme.colors.selectedTheme.white};
	}

	&.governance:hover {
		> div.governance {
			visibility: visible;
		}
	}

	&.socials:hover {
		> div.socials {
			visibility: visible;
		}
	}

	margin: 0px 20px;
	svg {
		margin-left: 5px;
	}
`;

const MenuContainer = styled(GridDivCenteredCol)`
	grid-gap: 24px;
	justify-self: end;
`;

export default Header;

const ComingSoonTag = styled(FlexDivCentered)`
	padding: 2px 5px;
	color: ${(props) => props.theme.colors.common.secondary};
	font-family: ${(props) => props.theme.fonts.bold};
	justify-content: center;
	margin-left: 8px;
	background: #202020;
	border-radius: 6px;
	border: ${(props) => ` 1px solid ${props.theme.colors.common.secondary}`};
	cursor: default;
	font-variant: all-small-caps;

	${media.lessThan('sm')`
		margin-left: 0px;
		padding: 2px 5px;
	`}
`;
