import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import EqualLogo from 'assets/svg-new/anchor/equal.svg';
import LockIcon from 'assets/svg-new/anchor/lock.svg';
import NoBorrowRateIcon from 'assets/svg-new/anchor/no-borrow-rate.svg';
import EasyRampingIcon from 'assets/svg-new/features/easy-ramping.svg';
import LowGasFeeIcon from 'assets/svg-new/features/low-gas-fee.svg';
import ZeroSlippage from 'assets/svg-new/features/zero-slippage.svg';
import {
	FlexDivCentered,
	FlexDivColCentered,
	FlexDivRow
} from 'components/layout/flex';
import { GridDiv } from 'components/layout/grid';
import { StackSection } from 'sections/homepage/section';
import { Copy, FeatureCard, Title } from 'sections/homepage/text';
import { SmallGoldenHeader, WhiteHeader } from 'styles/common';
import VoteNGovernIcon from 'assets/svg-new/earn/vote-n-govern.svg';
import media, { Media } from 'styles/media';

const ANCHORS = [
	{
		id: 'impermanent-loss',
		title: 'homepage.anchor.impermanent-loss.title',
		copy: 'homepage.anchor.impermanent-loss.description',
		image: <VoteNGovernIcon />,
	},
	{
		id: 'liquidation',
		title: 'homepage.anchor.liquidation.title',
		copy: 'homepage.anchor.liquidation.description',
		image: <ZeroSlippage />,
	},
	{
		id: 'funding-rate',
		title: 'homepage.anchor.funding-rate.title',
		copy: 'homepage.anchor.funding-rate.description',
		image: <EasyRampingIcon />,
	},
];

const ANCHORS_TRADERS = [
	{
		id: 'borrow-rate',
		title: 'homepage.trading-anchor.borrow-rate.title',
		copy: 'homepage.trading-anchor.borrow-rate.description',
		image: <NoBorrowRateIcon />,
	},
	{
		id: 'self-custody',
		title: 'homepage.trading-anchor.self-custody.title',
		copy: 'homepage.trading-anchor.self-custody.description',
		image: <LockIcon />,
	},
	{
		id: 'composability',
		title: 'homepage.trading-anchor.composability.title',
		copy: 'homepage.trading-anchor.composability.description',
		image: <LowGasFeeIcon />,
	},
];

const Anchor = () => {
	const { t } = useTranslation();

	const title = (
		<>
			<SmallGoldenHeader>{t('homepage.anchor.title')}</SmallGoldenHeader>
			<WhiteHeader>
				<Trans i18nKey={'homepage.anchor.description'} />
			</WhiteHeader>
			<GrayCopy>{t('homepage.anchor.copy')}</GrayCopy>
		</>
	);

	const titleTrader = (
		<>
			<SmallGoldenHeader>{t('homepage.trading-anchor.title')}</SmallGoldenHeader>
			<WhiteHeader>
				<Trans i18nKey={'homepage.trading-anchor.description'} />
			</WhiteHeader>
			<GrayCopy>{t('homepage.trading-anchor.copy')}</GrayCopy>
		</>
	);

	return (
		<>
			<StackSection>
				<Container>
					<FlexDivColCentered>{title}</FlexDivColCentered>
					<Media greaterThan="sm">
						<StyledFlexContainer>
							{ANCHORS.map(({ id, title, copy, image }) => (
								<FeatureCard key={id}>
									<FeatureIconContainer>{image}</FeatureIconContainer>
									<FeatureContentTitle>
										<CenteredTitle>{t(title)}</CenteredTitle>
									</FeatureContentTitle>
									<CenteredCopy>{t(copy)}</CenteredCopy>
								</FeatureCard>
							))}
						</StyledFlexContainer>
					</Media>
					<Media lessThan="sm">
						<StyledFlexDivColCentered>
							{ANCHORS.map(({ id, title, copy, image }) => (
								<FeatureCard key={id}>
									<FeatureIconContainer>{image}</FeatureIconContainer>
									<FeatureContentTitle>
										<CenteredTitle>{t(title)}</CenteredTitle>
									</FeatureContentTitle>
									<CenteredCopy>{t(copy)}</CenteredCopy>
								</FeatureCard>
							))}
						</StyledFlexDivColCentered>
					</Media>
				</Container>
			</StackSection>
			{/* <StackSection>
				<Container>
					<FlexDivColCentered>{titleTrader}</FlexDivColCentered>
					<Media greaterThan="sm">
						<StyledFlexContainer>
							{ANCHORS_TRADERS.map(({ id, title, copy, image }) => (
								<FeatureCard key={id}>
									<FeatureIconContainer>{image}</FeatureIconContainer>
									<FeatureContentTitle>
										<CenteredTitle>{t(title)}</CenteredTitle>
									</FeatureContentTitle>
									<CenteredCopy>{t(copy)}</CenteredCopy>
								</FeatureCard>
							))}
						</StyledFlexContainer>
					</Media>
					<Media lessThan="sm">
						<StyledFlexDivColCentered>
							{ANCHORS_TRADERS.map(({ id, title, copy, image }) => (
								<FeatureCard key={id}>
									<FeatureIconContainer>{image}</FeatureIconContainer>
									<FeatureContentTitle>
										<CenteredTitle>{t(title)}</CenteredTitle>
									</FeatureContentTitle>
									<CenteredCopy>{t(copy)}</CenteredCopy>
								</FeatureCard>
							))}
						</StyledFlexDivColCentered>
					</Media>
				</Container>
			</StackSection> */}
		</>
	);
};

const GrayCopy = styled(Copy)`
	margin-top: 17px;
	text-align: center;
	width: 446px;
	font-size: 18px;
	line-height: 100%;
	color: ${(props) => props.theme.colors.common.tertiaryGray};
	${media.lessThan('sm')`
		font-size: 16px;
		width: 336px;
		margin-bottom: 60px;
	`}
`;

const StyledFlexDivColCentered = styled(FlexDivColCentered)`
	width: 405px;
	margin: auto;
	padding: 0px;

	${media.lessThan('sm')`
		gap: 20px;
	`}
`;

const CenteredCopy = styled(Copy)`
	font-size: 15px;
	text-align: center;
	width: 300px;
	line-height: 100%;
	letter-spacing: -0.03em;
	color: ${(props) => props.theme.colors.common.tertiaryGray};
`;

const CenteredTitle = styled(Title)`
	font-family: ${(props) => props.theme.fonts.black};
	font-variant: all-small-caps;
	text-transform: uppercase;
	font-size: 24px;
`;

const StyledFlexContainer = styled(FlexDivRow)`
	width: 1160px;
	justify-content: center;
	gap: 20px;
	margin-top: 40px;
`;

const FeatureIconContainer = styled.div`
	padding-bottom: 15px;
	svg {
		width: 64px;
		height: 64px;
	}
	display: flex;
	justify-content: center;
`;

const FeatureContentTitle = styled(FlexDivCentered)`
	padding-bottom: 5px;
	justify-content: center;
`;

const Container = styled(GridDiv)`
	width: 100vw;
	overflow: hidden;
	justify-content: center;
	padding: 110px 0px;
`;

export default Anchor;
