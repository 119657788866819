import { useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from 'components/Button';
import Connector from 'containers/Connector';
import { NetworkId } from 'sdk/types/common';
import { isSupportedNetworkId } from 'utils/network';

import ConnectionDot from '../ConnectionDot';
import MobileWalletActions from './MobileWalletActions';
import { openModal } from 'state/balances/reducer';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectHasTestnetNFT } from 'state/balances/selectors';
import Webp from 'components/Webp';
import NFTIcon from 'assets/png/logo/synthesis_med_crop.png';

type MobileWalletButtonProps = {
	toggleModal(): void;
	closeModal(): void;
};

const MobileInviteButton = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const openInviteModal = useCallback(() => {
		dispatch(openModal());
	}, [dispatch]);

	const hasInvite = useAppSelector(selectHasTestnetNFT);

	return (
		<InviteButton
				size="small"
				variant="flat"
				noOutline
				onClick={openInviteModal}
				data-test-id="invite"
				mono
			>
				<Webp srcOrSrcset={NFTIcon} StyledImg={NFTImage}/>
				{hasInvite ? t('common.invite.with-invite') : t('common.invite.without-invite')}
			</InviteButton>
	)
}

const MobileConnectButton = () => {
	const { t } = useTranslation();
	const { openConnectModal: connectWallet } = useConnectModal();

	return (
		<ConnectButton
			size="small"
			variant="flat"
			noOutline
			onClick={connectWallet}
			data-testid="connect-wallet"
			mono
		>
			<ConnectionDot />
			{t('common.wallet.connect-wallet')}
		</ConnectButton>
	);
};

const MobileUnsupportedButton = () => {
	const { t } = useTranslation();
	const { openChainModal } = useChainModal();

	return (
		<ConnectButton
			size="small"
			variant="flat"
			data-testid="unsupported-network"
			mono
			onClick={openChainModal}
		>
			<ConnectionDot />
			{t('common.wallet.unsupported-network')}
		</ConnectButton>
	);
};

const MobileWalletButton: React.FC<MobileWalletButtonProps> = ({ toggleModal }) => {
	const { network, isWalletConnected } = Connector.useContainer();

	if (!isWalletConnected) {
		return (
		<>
			<MobileInviteButton />
			<MobileConnectButton />
		</>);
	} else if (isSupportedNetworkId(network?.id as NetworkId)) {
		return (
		<>
			<MobileInviteButton />
			<MobileWalletActions toggleModal={toggleModal} />
		</>)
	} else {
		return (
		<>
			<MobileInviteButton />
			<MobileUnsupportedButton />
		</>)
	}
};

const ConnectButton = styled(Button)`
	font-size: 13px;
`;

const InviteButton = styled(Button)`
	font-size: 13px;
	font-family: ${(props) => props.theme.fonts.mono};
	margin-right: 12px;
`

const NFTImage = styled.img`
    height: 40px;
`

export default MobileWalletButton;
