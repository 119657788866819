import Link from 'next/link';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InfluxLogo from 'assets/svg/logo/influx.svg';
import Button from 'components/Button';
import { FlexDivColCentered } from 'components/layout/flex';
import { GridDiv } from 'components/layout/grid';
import * as Text from 'components/Text';
import ROUTES from 'constants/routes';
import { StackSection } from 'sections/homepage/section';
import media from 'styles/media';

const Hero = () => {
	const { t } = useTranslation();

	return (
		<StackSection>
			<Container>
				{/* <Header>{t('homepage.hero.title')}</Header> */}
				<HeroImageContainer>
					<HeroImage>
						<InfluxLogo />
					</HeroImage>
				</HeroImageContainer>
				<ProductDescription>
					<Trans i18nKey={'homepage.hero.copy'} components={[<Emphasis />]} />
				</ProductDescription>
				<CTAContainer>
				</CTAContainer>
				{/* <HeroImageContainer>
					<Webp srcOrSrcset={MarketOrderPreview} StyledImg={HeroImage} />
				</HeroImageContainer> */}
			</Container>
		</StackSection>
	);
};

const Container = styled(FlexDivColCentered)`
	width: 100vw;
	overflow: hidden;
	justify-content: center;
	padding: 0px 0px;

`;

const Emphasis = styled.b`
	color: ${(props) => props.theme.colors.common.primaryWhite};
`;

const Header = styled(Text.Body).attrs({ weight: 'bold', mono: true })`
	max-width: 636px;
	font-size: 80px;
	line-height: 85%;
	text-align: center;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.common.primaryYellow};
	${media.lessThan('sm')`
		font-size: 40px;
		width: 346px;
		padding-top: 10px;
	`}
`;

const ProductDescription = styled(Text.Body)`
	font-family: 'AkkuratMonoLLWeb-Bold';
	font-variant: all-small-caps;
	text-transform: uppercase;
	max-width: 700px;
	font-size: 42px;
	line-height: 120%;
	text-align: center;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	padding-top: 16px;
	${media.lessThan('md')`
		font-size: 32px;
		width: 85vw;
	`}
`;

const HeroImageContainer = styled(GridDiv)`
	width: 100vw;
	overflow: hidden;
	display: grid;
	justify-content: center;
	margin-top: calc(230px);
	margin-bottom: 30px;
`;

const HeroImage = styled(GridDiv)`
	width: 1060px;
	${media.lessThan('lg')`
		width: 90vw;
	`}
	padding: 1px;
	border-radius: 8px;
`;

const CTAContainer = styled.div`
	margin: 60px 0px 130px 0;
	z-index: 1;
`;

export default Hero;
