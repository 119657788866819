import { FunctionComponent } from 'react';

import { EXTERNAL_LINKS } from 'constants/links';
import ROUTES from 'constants/routes';

export type Badge = {
	i18nLabel: string;
	color: 'yellow' | 'red' | 'gray';
};

export type SubMenuLink = {
	i18nLabel: string;
	link: string;
	badge?: Badge[];
	Icon?: FunctionComponent<any>;
	externalLink?: boolean;
};

export type MenuLink = {
	i18nLabel: string;
	link: string;
	links?: SubMenuLink[] | null;
	hidden?: boolean;
};

export type MenuLinks = MenuLink[];

export const getMenuLinks = (isMobile: boolean): MenuLinks => [
	// {
	// 	i18nLabel: 'header.nav.dashboard',
	// 	link: ROUTES.Dashboard.Home,
	// 	links: isMobile ? DASHBOARD_LINKS : null,
	// },
	{
		i18nLabel: 'header.nav.markets',
		link: ROUTES.Markets.Home,
	},
	{
		i18nLabel: 'header.nav.pools',
		link: ROUTES.Pools.Home,
	},
	{
		i18nLabel: 'header.nav.exchange',
		link: ROUTES.Exchange.Home,
	},
	{
		i18nLabel: 'header.nav.token',
		link: ROUTES.Faucet.Home,
	},
	{
		i18nLabel: 'header.nav.docs',
		link: EXTERNAL_LINKS.Docs.DocsRoot,
	},
];

export const DESKTOP_NAV_LINKS = getMenuLinks(false).filter((m) => !m.hidden);
export const MOBILE_NAV_LINKS = getMenuLinks(true).filter((m) => !m.hidden);

export const MENU_LINKS_WALLET_CONNECTED: MenuLinks = [];
