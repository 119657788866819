import { FC } from 'react';
import styled, { css } from 'styled-components';

import { EXTERNAL_LINKS } from 'constants/links';

import GitbookIcon from '../../../assets/svg/gitbook.svg';
import DiscordIcon from '../../../assets/svg/social/discord.svg';
import MirrorIcon from '../../../assets/svg/social/mirror-2.svg';
import TwitterIcon from '../../../assets/svg/social/twitter.svg';
import MediumIcon from '../../../assets/svg/social/medium.svg';
import TelegramIcon from '../../../assets/svg/social/telegram.svg';
import GithubIcon from '../../../assets/svg/social/github.svg';
import GalxeIcon from '../../../assets/svg/social/galxe.svg';

type LinksProps = {
	isMobile?: boolean;
};

const Links: FC<LinksProps> = ({ isMobile }) => (
	<LinkContainer isMobile={isMobile}>
		<a href={EXTERNAL_LINKS.Social.Medium} target="_blank" rel="noreferrer">
			<MediumIcon />
		</a>
		{/* <a href={EXTERNAL_LINKS.Social.Twitter} target="_blank" rel="noreferrer">
			<TwitterIcon />
		</a> */}
		<a href={EXTERNAL_LINKS.Social.Discord} target="_blank" rel="noreferrer">
			<DiscordIcon />
		</a>
		<a href={EXTERNAL_LINKS.Social.Telegram} target="_blank" rel="noreferrer">
			<TelegramIcon />
		</a>
		<a href={EXTERNAL_LINKS.Social.Galxe} target="_blank" rel="noreferrer">
			<GalxeIcon />
		</a>
	</LinkContainer>
);

const LinkContainer = styled.div<{ isMobile?: boolean }>`
	max-width: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 100px;
	margin-left: 15px;

	${(props) =>
		props.isMobile &&
		css`
			margin-top: 0;
			margin-left: 0;

			a {
				outline: none;
			}

			a:not(:last-child) {
				margin-right: 30px;
			}

			svg {
				width: 23px;
			}
		`}
`;

export default Links;
