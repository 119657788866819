import Head from 'next/head';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Anchor from 'sections/homepage/Anchor';
import Earning from 'sections/homepage/Earning';
import Hero from 'sections/homepage/Hero';
import Innovation from 'sections/homepage/Innovation';
import TradeNow from 'sections/homepage/TradeNow';
import GitHashID from 'sections/shared/Layout/AppLayout/GitHashID';
import HomeLayout from 'sections/shared/Layout/HomeLayout';
import media from 'styles/media';

type AppLayoutProps = {
	children: React.ReactNode;
};

type HomePageComponent = FC & { layout?: FC<AppLayoutProps> };

const HomePage: HomePageComponent = () => {
	const { t } = useTranslation();
	return (
		<>
			<Head>
				<title>{t('homepage.page-title')}</title>
			</Head>
			<HomeLayout>
				<Container>
					<Hero />
					<Innovation />
					<Anchor />
					<TradeNow />
					<GitHashID />
				</Container>
			</HomeLayout>
		</>
	);
};

export const Container = styled.div`
	width: 100%;
	margin: 0 auto;
	padding: 0px 20px 0 20px;
	${media.lessThan('sm')`
		padding: 50px 15px 0 15px;
	`}
`;

export default HomePage;
