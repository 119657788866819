import { useMemo } from 'react';

import Connector from 'containers/Connector';
import { MAIN_CHAIN } from 'containers/Connector/config';
import { notNill } from 'queries/utils/utils';

const useIsMain = () => {
	const { activeChain } = Connector.useContainer();
	const isMain = useMemo(
		() => (notNill(activeChain) ? [MAIN_CHAIN.id].includes(activeChain.id as 5) : false),
		[activeChain]
	);
	return isMain;
};

export default useIsMain;
